import { format } from 'date-fns';

export function dateFormatter(date: string, param?: string, dateFormat?: string) {
  const dateValue = date?.split('T');

  const isStr = /^[A-Za-z]/.test(dateValue[0]);
  const parsedDate = isStr
    ? date
    : !param
    ? formatTime(dateValue[1])
    : param === 'date'
    ? dateValue?.[0] || date
    : date;

  const [year, month, day] = parsedDate.split('-');

  switch (param) {
    case 'date':
      return `${month}/${day}/${year}`;
    case 'datetime':
      return format(parsedDate, 'MM/dd/yyyy HH:mm:ss');
    default:
      return parsedDate;
  }
}

export function removeDuplicatesFromArray(arr: any, key: string) {
  const uniqueArr = Array.from(new Set(arr.map((item) => JSON.stringify(item)))).map(JSON.parse);
  const uniqueArrByKey = Array.from(new Map(uniqueArr.map((item) => [item[key], item])).values());

  return uniqueArrByKey;
}

export function removeNullOrEmptyValues(obj: any) {
  obj = Object.fromEntries(
    Object.entries(obj).filter(([_, val]) => val !== null && val !== undefined && val !== '')
  );

  return obj;
}

export function validatePhoneNumber(phoneNumber: string) {
  console.log('phoneNumber', phoneNumber);

  const phoneRegex = /^(\+)?\d{11}$/;
  return phoneRegex.test(phoneNumber);
}

export function isPhoneNumberValid(phoneNumber: string, isSubmitBtnClicked: boolean) {
  const isValid = isSubmitBtnClicked
    ? !phoneNumber
      ? 'This is required'
      : validatePhoneNumber(phoneNumber)
      ? true
      : 'Invalid phone number'
    : true;

  return isValid;
}

export function tabTitle(newTitle: string) {
  return (document.title = newTitle + ' - oneXerp');
}

export function sortArrByKey(array: any, key: string) {
  return array.sort((a, b) => (a[key] > b[key] ? 1 : b[key] > a[key] ? -1 : 0));
}

export function getPrice(param, val?: string) {
  let total = 0;
  param?.paymentschedules?.map((item) => {
    if (val == 'total') {
      if (item.status == 'Paid') {
        total += Number(item.amount);
      }
    } else if (val == 'remaining') {
      if (item.status == 'Paid') {
        total += Number(item.amount);
      }
      total = Number(param.po_total_price) - total;
    } else {
      if (item.status == 'Approved for Payment') {
        total += Number(item.amount);
      }
    }
  });
  return total;
}
export const getS3Path = (fileName: string, module: string) => {
  const currentDate = new Date();
  const yyyy = currentDate.getFullYear().toString();
  let mm = (currentDate.getMonth() + 1).toString();
  if (mm.length == 1) {
    mm = `0${mm}`;
  }
  let dd = currentDate.getDate().toString();
  if (dd.length == 1) {
    dd = `0${dd}`;
  }

  return `${module}/${yyyy}/${mm}/${dd}/${fileName}`;
};

export const getValidations = () => ({
  messages: {
    required: 'This is required'
  },
  validators: {
    phoneNumber: {
      message: 'Invalid phone number',
      rule: (val) => /^(\+)?\d{11}$/i.test(val),
      required: true
    }
  }
});

export const currencyFormat = (amount: number) =>
  new Intl.NumberFormat('en-US', { style: 'currency', currency: 'USD' }).format(amount ?? 0);

export const formatTime = (timeString: string) => {
  let time = '';
  if (timeString) {
    const [hours, minutes] = timeString.split(':').map(Number);
    const period = hours >= 12 ? 'PM' : 'AM';
    const formattedHours = hours % 12 || 12;
    const formattedMinutes = minutes.toString().padStart(2, '0');
    time = `${formattedHours}:${formattedMinutes} ${period}`;
  }

  return time;
};

export const ALPHA_NUMERIC_DASH_REGEX = new RegExp('^[A-Za-z0-9 ]*$');

export const levenshteinDistance = (a: string, b: string): number => {
  let tmp;
  let i, j;
  const alen = a.length;
  const blen = b.length;
  const dist: number[][] = [];

  if (alen === 0) return blen;
  if (blen === 0) return alen;

  for (i = 0; i <= alen; i++) dist[i] = [i];
  for (j = 0; j <= blen; j++) dist[0][j] = j;

  for (i = 1; i <= alen; i++) {
    for (j = 1; j <= blen; j++) {
      tmp = a[i - 1] === b[j - 1] ? 0 : 1;
      dist[i][j] = Math.min(dist[i - 1][j] + 1, dist[i][j - 1] + 1, dist[i - 1][j - 1] + tmp);
    }
  }

  return dist[alen][blen];
};
